import React from 'react';

const Grid = (props) => {
	let mobile = (props.mobile === undefined) ? "" : props.mobile+"-columns-mobile ";
	let tablet = (props.tablet === undefined) ? "" : " "+props.tablet+"-columns-tablet ";
	const extraClass = (props.extraClass === undefined) ? "":props.extraClass;
	const style = props.style || {};
	return (
		<div className={props.columns+"-columns "+ mobile + extraClass + tablet} style={style}>
			{props.children}
		</div>
	)
};

export default Grid;