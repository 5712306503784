import configStore from './configStore';

const initialState = {
    account:{},
    form:{
        date:null,
        time:null
    },
    isLoading: false,
    confirmationModal: {
        show: false,
        content:{},
        approveFunction: null
    },
    servicesTotals:{
        numOfServices: 0,
        totalAmount: 0,
        totalTax: 0,
    },
    services:[],
    selectedJobType: null
};
const store = configStore(initialState);

export default store;