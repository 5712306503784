import React from 'react'
import './NotFound.scss'
import notFound from '@assets/images/notFound.svg'
import big404 from '@assets/images/404.svg'

const NotFound = () => {
    return (
        <div className="error-container">
            <img alt="Not found" src={notFound}/>
            <img className='big404' alt="404" src={big404}/>
            <span className="sub-title">The webpage you're trying to reach can't be found</span>
        </div>
    )
}

export default NotFound;

