import axiosInstance from "@api/axios";
import config from "../config";
import { setExtraProperties } from '@api/amplitude';

export const setAccount = (accountData) => async dispatch => {
    dispatch({type: 'FETCH_ACCOUNT', payload: accountData});
    setExtraProperties();
};

export const getExtendedProperties = (ac) => async dispatch => {
    const res = await axiosInstance.post(`${config.BOOKING_APP_API_URL}/getServices`, {
        ac: ac,
        uc: '',
        action: 'getServices'
    })

    if (res.data && res.data.data && res.data.data.length > 0) {
        const extendedProperties = JSON.parse(res.data.data[0].extended_properties)
        const servicesObj =Object.values(extendedProperties.items||[]).filter(item=>item.status==1);
        let services = Object.keys(servicesObj).map((k) => servicesObj[k])
        if (services.length > 0) {
            dispatch({type: 'FETCH_SERVICES', payload: services});
        }

        const paymentSettings = extendedProperties.payment
        dispatch({type: 'SET_PAYMENT_SETTINGS', payload: paymentSettings});
        setExtraProperties();
        return services
    }
};
