import React, { Component } from 'react';
import './ChooseTime.scss'
import {updateForm} from "@actions/formActions"
import {connect} from "react-redux"
import {createAvailableSlots} from "@helpers/calendarHelper";
import moment from 'moment';

class ChooseTime extends Component {

    state = {
        date: "",
        timeSlots: [],
        selectedSlot: null,
        finishedLoading: false,
    };

    componentDidMount() {
        this.setProps(this.props);
        // if(this.props.form.time){
        //     this.setState({selectedSlot: this.props.form.time});
        // }
    }
    //set available times to draw
    setProps = ({ choosenDay, takenSlots, workingTimes, slotLength, scheduleOffset, updateForm, availableBased , availableJobsPerSlot, totalTechs}) => {   
        if(!choosenDay) return;
        let availableSlots = createAvailableSlots(choosenDay, takenSlots, workingTimes, slotLength, scheduleOffset, availableBased, availableJobsPerSlot, totalTechs)        
        
        this.setState({timeSlots: availableSlots, slotLength: slotLength, finishedLoading: true});
        return;
    }

    onSlotSelect = (slot) => {
        this.props.updateForm({type:'time',data:slot})
        this.setState({selectedSlot: slot});
    }


    render() {

        if(!this.props.form.date){
            return null
        }

        return (
            <div className="choose-time">
                <div className="available-time">Available time slots</div>
                <div className="slots-wrapper">
                    {this.state.timeSlots.map(slot => {
                        const t1 = moment().hour(0).minute(0).add(slot, 'hours')
                        return (
                            <div
                                key={slot}
                                className={`slot ${slot === this.state.selectedSlot ? 'selected' : ''}`}
                                onClick={() => this.onSlotSelect(slot)}
                            >
                                {t1.format('h:mma')}
                            </div>
                        );
                    })}
                    {
                        (!this.state.timeSlots || this.state.timeSlots.length == 0) && this.state.finishedLoading &&
                        <div className="no-available-slots">
                            <div>There are no available appointments for this date.</div>
                            <div className="second-row">Please choose another date and try again</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    
    return {
        account: state.account,
        form: state.form
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateForm: (payload) => dispatch(updateForm(payload)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseTime);
