import React from 'react';
import './StepTitle.scss'

const StepTitle = (props) => {
    return (
        <div className="step-title">
            {
                !props.disableBack && <div className="back-wrapper" onClick={() => props.back()}>
                    <i className="fas fa-arrow-left"></i>
                    <div className="back">BACK</div>
                </div>
            }

            <div className={`title ${!props.disableBack ? "margin-top" : ""}`}>
                {props.title}
                <span>{props.subtitle}</span>
            </div>

        </div>
    )
}



export default StepTitle;

