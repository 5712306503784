import * as FullStory from '@fullstory/browser';

export function initFullStory(accountId) {
    FullStory.init(
        {
            orgId: '6MW14',
            devMode: process.env.REACT_APP_STAGE !== 'prod',
            recordOnlyThisIFrame: true
        }
    );
    FullStory.identify(accountId);
}

export function fullStoryEvent(eventName, props = {}) {
    FullStory.event(eventName, props);
}

