import amplitude from "amplitude-js";
import config from '../config';
import store from '../store';


amplitude.getInstance().init(config.AMPLITUDE_API_KEY, null, {
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true
});

function getPaymentSolution(gateway) {
  switch(gateway){
    case 'workiz-stripe':
      return 'stripe';
    case 'workiz':
      return 'wepay';
    default:
      return 'null';
  }
}

export function setExtraProperties() {
  const storeData = store.getState();
  amplitude.getInstance().setUserProperties({
    account_id: storeData?.account?.accountId,
    paymentSolution: getPaymentSolution(storeData?.account?.paymentSettings?.gateway),
    env: 'BookingV2'
  });

  amplitude.getInstance().setGroup("account", `${storeData?.account?.accountId}`);
}

export function amplitudeEvent(eventName, props = {}) {
  amplitude.getInstance().logEvent(eventName, props)
}
