const formReducer = (state = [], action) => {
    switch (action.type) {
    case 'UPDATE_FORM':
        return { ...state, [action.payload.type]: action.payload.data };
    case 'SEND_FORM':
        return state;
    default:
        return state;
    }
};

export default formReducer;
