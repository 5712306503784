import moment from 'moment';

export function createAvailableSlots(choosenDay, takenSlots, workingTimes, slotLength, scheduleOffset, availableBased, avaibleJobsPerSlot, totalTechs){    
    const date = moment(choosenDay).format('DD-MM-YY');
    let availableSlots = [];
    availableSlots = createSlotsBySettings(date, workingTimes, slotLength);     
    if(availableBased != 0 && takenSlots[date]){ 
        console.log(date, 'date');
        availableSlots = availableSlots.filter((slot, index)=>{
            return isAvailable(takenSlots[date][index], totalTechs, avaibleJobsPerSlot);
        })   
        console.log('-------------');     
    }
    const firstPossibleAvailableDate = moment(new Date()).add(scheduleOffset, 'hours')
    //firs avaibele date
    if(date === moment(firstPossibleAvailableDate).format('DD-MM-YY')){
        const availableFromHour = firstPossibleAvailableDate.hours(); 
        availableSlots = availableSlots.filter(item => item > availableFromHour);        
    } else if(firstPossibleAvailableDate.isAfter(choosenDay)){
        availableSlots = [];
    }
    return availableSlots
}

function isAvailable (currentHour, totalTechs, avaibleJobsPerSlot){ 
    if(currentHour){
        console.log(totalTechs, 'totalTechs');
        console.log(currentHour, 'currentHour');
        const availableTech = totalTechs - currentHour['tech_in_job'] - currentHour['tech_in_day_off']
        switch(true){
            case (availableTech <= 0):
                return false;
            case (availableTech < avaibleJobsPerSlot):
                return  true;
            case (0 < avaibleJobsPerSlot - currentHour['tech_in_job']):
                return  true;
            default:
                return false;
        }
    }else if(currentHour === undefined){
        return true;
    }
}

// type 1
// techA - type 1 free
// techB - type 2 not free


function createSlotsBySettings (choosenDay, workingTimeSettings, slotLength){
    const timeSlots = [];
    let slots = [];                
    const dayOfWeek = moment(choosenDay, "DD-MM-YY").isoWeekday() - 1; 
    if (workingTimeSettings[dayOfWeek]) {
        const lastSlot = workingTimeSettings[dayOfWeek].lastSlot ? workingTimeSettings[dayOfWeek].lastSlot : Math.floor(24/slotLength);
        const startTime = workingTimeSettings[dayOfWeek].start || 0;  
        for (let i = 0; i < lastSlot; i++) {
            timeSlots.push((i * slotLength) + startTime);
        }
        slots = timeSlots;
    }
return slots;
}