import React from 'react'
import {connect} from "react-redux";
import './Header.scss'
import workiz from "@assets/images/poweredBy.svg";

const Header = (props) => {
    return (
        <div className="header">
            <div className={`header-inner ${!props.account.accountLogo ? 'no-logo' : ''}`}>
                <div className="left-side">
                    {props.account.accountLogo && props.account.uselogo ? <img
                        className="account-logo"
                        alt={props.account.title}
                        src={props.account.accountLogo}
                    /> : ""}
                    <div className="content">
                        <div className="account-name">{props.account.title}</div>
                        <div className="account-slogan">{props.account.description}</div>
                    </div>
                </div>
                <div className={"right-side"}>
                    <a target="_blank" rel="noreferrer" href="https://www.workiz.com/">
                        <img alt={''} className="workiz-logo" src={workiz}/>
                    </a>
                    <div className="shopping-cart-wrapper">
                        <div className="amount">${props.totalAmount}</div>
                    </div>

                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        totalAmount: state.servicesTotals.totalAmount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

