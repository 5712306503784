const servicesReducer = (state = [], action) => {
    switch (action.type) {
    case 'FETCH_SERVICES':
        const services = action.payload;
        for (const i in services) {
            services[i].quantity = 0;
        }
        return services;
    case 'UPDATE_SERVICE_QUANTITY':
        return state.map((service, index) => {
            if (service.p_id === action.payload.serviceId) {
                service.quantity = action.payload.action === 'add' ? service.quantity + 1 : service.quantity - 1;
            }
            return service;
        });
    default:
        return state;
    }
};

export default servicesReducer;
