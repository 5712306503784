import React  from 'react';
import './OrderDetailsRow.scss'
import OrderDetailsTitle from "../OrderDetailsTitle/OrderDetailsTitle"

const OrderDetailsRow = (props) => {

    return (
        <div className="progress-line-step">
            <div className={`progress-line ${props.activeStep > props.stepNum ? 'progress-line-completed' : ''}`}></div>
            <OrderDetailsTitle activeStep={props.activeStep} title={props.text} stepNum={props.stepNum}/>
        </div>
    )
}



export default OrderDetailsRow;

