import React, {useState} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';


const MapContainer = (props) => {

    const [lat,setLat] = useState(null)
    const [lng,setLng] = useState(null)

    const fetchPlaces = (mapProps, map) => {
        const {google} = mapProps;
        const service = new google.maps.places.PlacesService(map);

        let request = {
            query: props.address,
            fields: ['name', 'geometry'],
        };

        service.findPlaceFromQuery(request, function(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                setLat(results[0].geometry.location.lat())
                setLng(results[0].geometry.location.lng())
            }
        });
    }


    return (
        <div>
            <Map
                google={props.google}
                containerStyle={props.containerStyle}
                center={{
                    lat: lat,
                    lng: lng
                }}
                onReady={fetchPlaces}
                zoom={12}
            >
                <Marker
                    position={{lat: lat, lng: lng}} />
            </Map>
        </div>
    );

}

export default GoogleApiWrapper({
    apiKey: (`AIzaSyDpS7k_IwWUbrh_L8iL5AVoPdbsLuxPKKQ`)
})(MapContainer)