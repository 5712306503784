import React, {Component} from 'react';
import {connect} from "react-redux";
import './ClientDetails.scss'

import uploadFilesImg from '@assets/images/upload.png';
import pdfFile from '@assets/images/pdfFile.png';
import removeFile from '@assets/images/removeFile.svg';
import canadaStates from '@assets/js/canadaStates.json'
import usStates from '@assets/js/usStates.json'
import {trackEventToAll} from "@helpers/eventsHelper";

import * as dayjs from 'dayjs'
import {
    MenuItem,
    TextField,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import StepTitle from "@components/StepTitle/StepTitle";

import {saveBooking, updateForm, uploadFiles} from "@actions/formActions";
import {setIsLoading} from "@actions/isLoadingActions";
import {getPaymentAmount} from "@helpers/totalAmountHelper";

const allowedFileTypes = ['jpg', 'jpeg', 'gif', 'png', 'jpe', 'svg', 'pdf', 'gif', 'svg+xml']
const canadianZipRegex = /(^[a-zA-Z0-9]{3}(\s[a-zA-Z0-9]{3}){0,1}$)/;
const americanZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
class ClientDetails extends Component {


    fieldsToInit = ['name','email','phone','address','state','city','zip','description']
    errorColor = "#FF6F64"
    customFields = []
    customFieldsKeys = {}
    isUsOrCanada =  this.props.account.region !== 'NON' && this.props.account.region !== null;
    isCanadaRegion = this.props.account.region == "CA"
    zipLabel = this.isCanadaRegion ? "Postal code" : "Zip code";
    stateLabel = this.isCanadaRegion ? "Region" : "State";
    state = {
        validations: {
            user_name: {
                error: '',
                status: false
            },
            email_address: {
                error: '',
                status: false
            },
            primary_phone: {
                error: '',
                status: false
            },
            address: {
                error: '',
                status: false
            },
            city: {
                error: '',
                status: false
            },
            state: {
                error: '',
                status: false
            },
            zipcode: {
                error: '',
                status: false
            },
            message: {
                error: '',
                status: false
            }
        },
        fileError: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: this.isUsOrCanada ? this.props.account.region : "",
        message: '',
        customFields: {},
        customFieldsKeys: {},
        images: [],
        showRequired: this.props.showRequired
    };

    initStateVariable = (value) => {
        if(this.props.form[value]){
            const obj = {}
            obj[value] = this.props.form[value]
            this.setState(obj,() => {
                if(value.length > 0){
                    this[`validate${value.charAt(0).toUpperCase() + value.slice(1)}`]();
                }
            })
        }
    }

    customFieldsPrep(){
        if(this.props.account.userFields){
            let {validations,customFields} = this.state
            for (const [key, field] of Object.entries(this.props.account.userFields)) {
                if ('options' in field) {
                    this.customFields.push({
                        ...field,
                        id: key
                    })

                    validations[key] = {
                        error: '',
                        status: false
                    }

                    customFields[field.label] = field.type === 'datetime' ? '' : ''
                }
            }
            this.setState({validations})
            this.setState({customFields})
        }
    }

    componentDidMount() {
        this.customFieldsPrep()
        this.fieldsToInit.forEach((field) => this.initStateVariable(field))
    }

    setRequired(fieldKey){
        let {validations} = this.state
        if(validations[fieldKey].status == false ){
            validations[fieldKey].error = "Required field"
        }
        this.setState({validations})
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.showRequired && this.props.showRequired){
            for(const fieldKey in this.props.account.userFields){
                const field = this.props.account.userFields[fieldKey];
                field.required && this.setRequired(fieldKey)
            }
            if(this.isAddressMandatory()){
                this.setRequired('zipcode');
                this.setRequired('address');
            }
        }
    }

    validateCustomTextField = (field,errorMsg,length) => {
        let {validations} = this.state

        if(!field.required){
            validations[field.id].error = ""
            validations[field.id].status = true
        }else{
            if (this.state.customFields[field.label].length < 1) {
                validations[field.id].error = `${field.label} ${errorMsg}`
                validations[field.id].status = false
            } else {
                validations[field.id].error = ""
                validations[field.id].status = true
            }
        }


        this.setState({validations})
        this.isFormValid()
    }

    validateSelect = (field) => {
        this.props.updateForm({type:field.id,data:this.state[field.id]})
        let {validations} = this.state

        if(!field.required){
            validations[field.id].error = ""
            validations[field.id].status = true
        }else{
            if (this.state.customFields[field.label].length < 1) {
                validations[field.id].error = `${field.label} must be selected`
                validations[field.id].status = false
            } else {
                validations[field.id].error = ""
                validations[field.id].status = true
            }
        }


        this.setState({validations})
        this.isFormValid()
    }

    validateName = () => {
        this.props.updateForm({type:'name',data:this.state.name})
        let {validations} = this.state
        if (this.state.name.length < 2) {
            validations.user_name.error = "Name must have at least two characters"
            validations.user_name.status = false
        } else if (/\d/.test(this.state.name)) {
            validations.user_name.error = "Name must have at least two characters"
            validations.user_name.status = false
        } else {
            validations.user_name.error = ""
            validations.user_name.status = true
        }
        this.setState({validations})
        this.isFormValid()
    }

    validateEmail = () => {
        this.props.updateForm({type:'email',data:this.state.email})
        let {validations} = this.state
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.state.email).toLowerCase())) {
            validations.email_address.error = "Email is not valid"
            validations.email_address.status = false
        } else {
            validations.email_address.error = ""
            validations.email_address.status = true

        }
        this.setState({validations})
        this.isFormValid()
    }

    validatePhoneNumber = (str) => {
        return ((str || "").match(/\d/g) || "").length === 10
    }
    validatePhone = () => {
        this.props.updateForm({type:'phone',data:this.state.phone})

        let {validations} = this.state

        const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (this.props.account.userFields.primary_phone?.required && (this.state.phone.length < 4 || !pattern.test(this.state.phone))) {
            validations.primary_phone.error = "Phone number is not valid"
            validations.primary_phone.status = false
        }else if (!pattern.test(this.state.phone)) {
            validations.primary_phone.error = "Phone number is not valid"
            validations.primary_phone.status = false
        } else {
            validations.primary_phone.error = ""
            validations.primary_phone.status = true
        }
        this.setState({validations})
        this.isFormValid()

    }

    validateAddress = () => {
        this.props.updateForm({type:'address',data:this.state.address})
        this.updateFullAddress()
        if (this.props.account.userFields.address?.required || this.isAddressMandatory()) {
            let {validations} = this.state

            if (this.state.address.length < 2) {
                validations.address.error = "Address is not valid"
                validations.address.status = false
            } else {
                validations.address.error = ""
                validations.address.status = true
            }
            this.setState({validations})
            this.isFormValid()
        }
    }

    validateCity = () => {
        this.props.updateForm({type:'city',data:this.state.city})
        if (this.props.account.userFields.city.required) {
            let {validations} = this.state

            if (this.state.city.length < 2) {
                validations.city.error = "City is not valid"
                validations.city.status = false
            } else {
                validations.city.error = ""
                validations.city.status = true
                this.updateFullAddress()
            }
            this.setState({validations})
            this.isFormValid()
        }
    }

    validateState = () => {
        this.props.updateForm({type:'state',data:this.state.state})
        if (this.props.account.userFields.state.required) {
            let {validations} = this.state
            if (this.state.state.length < 2) {
                validations.state.error = `${this.stateLabel} is not valid`
                validations.state.status = false
            } else {
                validations.state.error = ""
                validations.state.status = true
                this.updateFullAddress()
            }
            this.setState({validations})
            this.isFormValid()
        }
    }

    validateZip = () => {
        this.props.updateForm({type:'zip',data:this.state.zip})
        
        const zipRegex = this.isCanadaRegion ? canadianZipRegex : americanZipRegex;
        let {validations} = this.state
        if (!zipRegex.test(this.state.zip)) {
            validations.zipcode.error = `${this.zipLabel} is not valid`
            validations.zipcode.status = false
        } else {
            validations.zipcode.error = ""
            validations.zipcode.status = true
            this.updateFullAddress()
        }
        this.setState({validations})
        this.isFormValid()
    }

    validateDescription = () => {
        let {validations} = this.state
        this.props.updateForm({type:'description',data:this.state.message})
        if (this.state.message.length < 2) {
            validations.message.error = "Description is not valid"
            validations.message.status = false
        } else {
            validations.message.error = ""
            validations.message.status = true
        }
        this.setState({validations})
        this.isFormValid()
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        },() => {
            if(name === 'state'){
                this.validateState()
            }
            this.props.updateForm({type:name,data:value})
            this.isFormValid()
        });
    }

    handleCustomInputChange = (event,field) => {
        const target = event.target;
        let value = target.value;
        let {customFields,customFieldsKeys} = this.state
        if(field.type === 'checkbox'){
            value = !customFields[field.label]
        }

        customFields[field.label] = value
        customFieldsKeys[field.id] = value

        this.setState({
            customFields:customFields
        },() => {
            this.props.updateForm({type:customFields,data:customFields})
            this.props.updateForm({type:'customFieldsKeys',data:customFieldsKeys})
            this.isFormValid()
        });
    }

    isFormValid = () => {
        let valid = true;

        for (const [key] of Object.entries(this.state.validations)) {
            if (((this.props.account.userFields[key]?.required && this.props.account.userFields[key].type !== 'checkbox' ) || (this.isAddressMandatory() && (key == 'zipcode' || key == 'address') ) )
                && this.state.validations[key].status === false) {
                valid = false
            }
        }

        this.props.isValid(valid);
        return valid
    }

    isFileNameExists = (fileName) => {
        let flag = false;
        this.state.images.forEach(image => {
            if (image.name === fileName) {
                flag = true
                return
            }
        })
        return flag
    }

    imageChangeHandler = (event) => {
        const file = event.target.files[0]
        const fileExtension = this.getFileExtension(file.type)
        const fileSize = (file?.size / (1024 * 1024)).toFixed(3)
        this.setState({fileError: ''})

        if (this.isFileNameExists(file.name)) {
            this.setState({fileError: `Duplicate file name`})
            return false
        }

        if (!allowedFileTypes.includes(fileExtension)) {
            this.setState({fileError: `Invalid file type (${fileExtension})`})
            return false
        }
        if (fileSize > 3.5) {
            this.setState({fileError: `File is too big (Maximum 3.5 MB)`})
            return false
        }
        this.setState({images: [...this.state.images, file]})
    }

    renderStates() {
        if(this.state.country === 'US'){
            return usStates.map((state) => <MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>)
        }else if(this.state.country === 'CA'){
            return canadaStates.map((state) => <MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>)
        }
    }

    removeFile(index) {
        const images = this.state.images
        images.splice(index, 1)
        this.setState({images: images})
    }

    getFileExtension(fileType) {
        return fileType.split("/")[1]
    }

    clearAllFiles = () => {
        this.setState({images: []})
    }

    next = async () => {
        if(this.props.isLoading){
            return false
        }
        if (this.state.images.length > 0) {
            const data = new FormData()
            for (let i in this.state.images) {
                data.append('file', this.state.images[i])
            }
            const fileNames = await this.props.uploadFiles(data)
            await this.submit(fileNames)
        } else {
            await this.submit()
        }
    }

    updateFullAddress = () => {
        const fullAddress = {
            address:this.state.address,
            zipcode: this.state.zip,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country
        }
        this.props.updateForm({type:'fullAddress',data:fullAddress})
    }

    submit = async (filesUrls = []) => {
        let jobDate = dayjs(new Date(this.props.form.date)).hour(0).minute(0).add(this.props.form.time, 'hours')
        let jobEndDate = dayjs(new Date(this.props.form.date)).hour(0).minute(0).add(this.props.form.time, 'hours').add(this.props.account.slotLength, 'hours')
        const fullName = this.state.name.split(" ")
        const firstName = fullName[0]
        const lastName = fullName.length > 1 ? fullName.splice(1, fullName.length-1).join(' ') : ""
        const services = this.props.services.filter(service => service.quantity > 0)

        this.props.setIsLoading(true)
        let hasPayment = true
        if(this.props.account.paymentSettings?.deposit === -1 || services.length === 0 || !this.props.totalAmount){
            hasPayment = false
            const bookingPayload =  {
                amount: getPaymentAmount(),
                deposit: 0,
                ac: this.props.account.ac,
                first_name: firstName,
                last_name: lastName,
                email: this.state.email,
                description: this.state.message,
                account_id : this.props.account.accountId,
                ad_group : this.props.account.adGroup,
                ad_group_name: this.props.account.adGroupName,
                address: this.state.address,
                city: this.state.city,
                zip: this.state.zip,
                state: this.state.state,
                country: this.state.country,
                totalAmount: this.props.servicesTotalAmount,
                phone: this.state.phone,
                job_date: jobDate.format("MM/DD/YYYY H:mm"),
                job_end_date: jobEndDate.format("MM/DD/YYYY H:mm"),
                services: services,
                hasPayment: hasPayment,
                customFields: this.state.customFields,
                customFieldsKeys: this.state.customFieldsKeys,
                files: filesUrls,
                job_type: this.props.selectedJobType,
                job_type_name: this.props.jobTypes.find(jobType => jobType.id === this.props.selectedJobType)?.type_name || ""
            }


            await this.props.saveBooking(bookingPayload)
        }

        this.updateFullAddress()
        this.props.updateForm({type:'customFields',data:this.state.customFields})
        this.props.updateForm({type:'customFieldsKeys',data:this.state.customFieldsKeys})
        this.props.updateForm({type:'files',data:filesUrls})

        trackEventToAll('bookingFinishedDetailsStep',{
            attachments: filesUrls.length
        });

        if(services.length === 0){
            hasPayment = false
        }
        this.props.onDone(hasPayment)
        this.props.setIsLoading(false)
    }

    renderImages() {
        return this.state.images.map((image, index) => {
            return (
                <div className="preview-image-wrapper" key={image.name + image.lastModified}>
                    {
                        this.getFileExtension(image.type) === 'pdf'
                            ? <img alt='' className="pdf-file" src={pdfFile}/>
                            : <img alt='' className="image-preview" src={URL.createObjectURL(image)}/>
                    }
                    <img alt='remove-file' className="image-delete" src={removeFile} onClick={() => this.removeFile(index)}/>
                </div>
            )
        })
    }

    ellipsisLabel = (label) => {
        return label.length > 37 ? `${label.substring(0,34)}...` : label
    }

    renderInputField(field,config={}) {
        return (
            <TextField
                value={this.state.customFields[field.label]}
                id={`custom-field-${field}`}
                label={this.ellipsisLabel(field.label)}
                name={field.id}
                key={field.id}
                InputLabelProps={field.type === 'datetime' ? { shrink: true } : {}}
                type={'type' in config ? config.type : ''}
                helperText={this.state.validations[field.id] ? this.state.validations[field.id].error : ''}
                FormHelperTextProps={{ style: { color: this.errorColor }}}
                variant="outlined"
                className="input-field"
                onChange={(e) => this.handleCustomInputChange(e,field)}
                onBlur={() => this.validateCustomTextField(field,config.errMsg,config.length)}
            />
            )
    }


    renderTextArea(field,config={}) {
        return (
            <FormControl key={field.id}>
                <textarea
                    onChange={(e) => this.handleCustomInputChange(e,field)}
                    className="textarea"
                    name={field.id}
                    key={field.id}
                    onBlur={() => this.validateCustomTextField(field,config.errMsg,config.length)}
                    value={this.state.customFields[field.label]}
                    placeholder={this.ellipsisLabel(field.label)}></textarea>
                <FormHelperText style={{color: this.errorColor, margin: '8px 12px 0'}} error={true}>{this.state.validations[field.id].error }</FormHelperText>
            </FormControl>
        )
    }

    renderCheckbox(field){
        return (
            <FormControlLabel
                key={field.id}
                control={
                    <Checkbox
                        checked={this.state.customFields[field.label]}
                        onChange={(e) => this.handleCustomInputChange(e,field)}
                        name={field.id}
                    />
                }
                label={this.ellipsisLabel(field.label)}
            />
        )
    }


    renderSelect(field,config={}) {
        const options = field.options.split(";");
        return (
            <FormControl key={field.id} className="custom-select">
                <InputLabel className="state-floating-label">{field.label}</InputLabel>
                <Select
                    key={field.id}
                    value={this.state.customFields[field.label]}
                    label={this.ellipsisLabel(field.label)}
                    name={field.id}
                    className="select-field"
                    onBlur={() => this.validateCustomTextField(field,config.errMsg,config.length)}
                    onChange={(e) => this.handleCustomInputChange(e,field)}
                >
                    {options.map((option,index) => <MenuItem key={index} value={option}>{option}</MenuItem>)}
                </Select>
                {!this.state.validations[field.id].status &&
                <FormHelperText style={{color: this.errorColor, margin: '8px 12px 0'}} error={true}>{this.state.validations[field.id].error }</FormHelperText>}
            </FormControl>
        )
    }

    renderCityField(isSmall){
        return (
            <TextField
                value={this.state.city}
                label="City"
                name="city"
                variant="outlined"
                helperText={this.state.validations.city.error}
                FormHelperTextProps={{ style: { color: this.errorColor }}}
                className={isSmall ? "small-input-field" : "input-field"}
                onChange={this.handleInputChange}
                onBlur={() => this.validateCity()}
                onKeyUp={() => this.validateCity()}
            /> 
        )
    }

    renderCustomFields(field,config={}) {

        return this.customFields.map(field => {
            switch (field.type) {
                case 'text':
                    return this.renderInputField(field,{errMsg:" must be filled",length: 2})
                case 'textarea':
                    return this.renderTextArea(field,{errMsg:" must be filled",length: 2})
                case 'select':
                    return this.renderSelect(field,{errMsg:" must be select",length: 1})
                case 'int':
                    return this.renderInputField(field,{type:'number',errMsg:" must be selected",length: 1})
                case 'checkbox':
                    return this.renderCheckbox(field)
                case 'datetime':
                    return this.renderInputField(field,{type:'date',errMsg:" must be selected",length: 1})
                default:
                    return ''
            }
        })
    }

    isAddressMandatory = () => {
        return this.props.numOfServices > 0 && this.props.account.paymentSettings?.deposit >= 0
    }

    render() {
        return (
            <div className="client-details">
                <StepTitle back={() => this.props.goBack()} title="Your Details" subtitle=" / Please fill the form below"/>
                <div className="form-wrapper">
                    <div className="fields">
                        <div className="left-wrapper">
                            <TextField
                                value={this.state.name}
                                id="your-name"
                                label="Your name"
                                name="name"
                                helperText={this.state.validations.user_name.error}
                                FormHelperTextProps={{ style: { color: this.errorColor }}}
                                variant="outlined"
                                className="input-field"
                                onChange={this.handleInputChange}
                                onKeyUp={() => this.validateName()}
                                onBlur={() => this.validateName()}
                            />
                            <TextField
                                value={this.state.email}
                                id="standard-error"
                                name="email"
                                label="Email address"
                                helperText={this.state.validations.email_address.error}
                                FormHelperTextProps={{ style: { color: this.errorColor }}}
                                variant="outlined"
                                className="input-field"
                                onChange={this.handleInputChange}
                                onKeyUp={() => this.validateEmail()}
                                onBlur={() => this.validateEmail()}
                            />
                            {
                                this.props.account.userFields?.primary_phone &&
                                <TextField
                                    value={this.state.phone}
                                    id="standard-error"
                                    label="Phone number"
                                    name="phone"
                                    helperText={this.state.validations.primary_phone.error}
                                    FormHelperTextProps={{style: {color: this.errorColor}}}
                                    variant="outlined"
                                    className="input-field phone"
                                    onChange={this.handleInputChange}
                                    onKeyUp={() => this.validatePhone()}
                                    onBlur={() => this.validatePhone()}
                                />
                            }
                            {
                                (this.props.account.userFields?.address || this.isAddressMandatory()) &&
                                <TextField
                                    value={this.state.address}
                                    label="Address"
                                    name="address"
                                    variant="outlined"
                                    helperText={this.state.validations.address.error}
                                    FormHelperTextProps={{ style: { color: this.errorColor }}}
                                    className="input-field"
                                    onChange={this.handleInputChange}
                                    onBlur={() => this.validateAddress()}
                                />
                            }
                            {
                                this.props.account.userFields?.city && this.isUsOrCanada &&
                                this.renderCityField(false)   
                            }
                            <div className="form-flex-row second">
                            {this.props.account.userFields?.state && <FormControl>
                                    <InputLabel className="state-floating-label">{this.stateLabel}</InputLabel>
                                    <Select
                                        disabled={!this.state.country}
                                        value={this.state.state}
                                        label="State"
                                        name="state"
                                        className="select-field"
                                        onBlur={() => this.validateState()}
                                        onChange={this.handleInputChange}
                                    >
                                        {this.renderStates()}
                                    </Select>
                                    {!this.state.validations.state?.status &&
                                    <FormHelperText style={{color: this.errorColor, margin: '8px 12px 0'}} error={true}>{this.state.validations.state?.error}</FormHelperText>}
                                </FormControl>}
                                {this.props.account.userFields?.city && !this.isUsOrCanada && this.renderCityField(true)}
                                { (this.props.account.userFields?.zipcode || this.isAddressMandatory()) &&
                                    <TextField
                                        value={this.state.zip}
                                        label={this.zipLabel}
                                        name="zip"
                                        helperText={this.state.validations.zipcode.error}
                                        FormHelperTextProps={{ style: { color: this.errorColor }}}
                                        variant="outlined"
                                        className="small-input-field"
                                        onBlur={() => this.validateZip()}
                                        onKeyUp={() => this.validateZip()}
                                        onChange={this.handleInputChange}
                                    />
                                }
                            </div>
                            <div className="custom-fields">{this.renderCustomFields()}</div>
                        </div>
                        <div className="right-wrapper">
                            {this.props.account.userFields?.message &&
                                <textarea
                                    onChange={this.handleInputChange}
                                    className="description"
                                    name="message"
                                    onBlur={() => this.validateDescription()}
                                    onKeyUp={() => this.validateDescription()}
                                    value={this.state.message}
                                    placeholder="Add your description here..."></textarea>
                            }
                            {
                                !this.state.validations.message.status > 0 && <div className="description-error">{this.state.validations.message.error}</div>
                            }


                            <div className={`attach-image-wrapper ${!this.props.account.userFields?.message ? 'no-description' : ''}`}>
                                {this.state.images.length === 0 &&
                                <div className="no-images-yet">
                                    <label className="file-input" htmlFor="file-input">
                                        <img alt='' src={uploadFilesImg}/>
                                        <div className="text">Upload files here</div>
                                    </label>
                                    <input onChange={this.imageChangeHandler} id="file-input" type="file"/>

                                </div>
                                }
                                {this.state.images.length > 0 &&
                                <div className="has-images">
                                    <div className="attachments-title">Attachments</div>
                                    <input onChange={this.imageChangeHandler} id="file-input" type="file"/>
                                    <div className="preview-files">
                                        <div className="files">
                                            {this.renderImages()}
                                            <label className="upload-files-wrapper" htmlFor="file-input">
                                                <div className="plus-files">+</div>
                                            </label>
                                        </div>
                                        <div className="clear-all-wrapper" onClick={() => this.clearAllFiles()}>
                                            <div className="clear-all-text">Clear All</div>
                                        </div>
                                    </div>

                                </div>
                                }
                                <div className="file-error">{this.state.fileError} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        form: state.form,
        services: state.services,
        numOfServices: state.servicesTotals.numOfServices,
        totalAmount: state.servicesTotals.totalAmount,
        isLoading: state.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveBooking: (payload) => dispatch(saveBooking(payload)),
        uploadFiles: (payload) => dispatch(uploadFiles(payload)),
        updateForm: (payload) => dispatch(updateForm(payload)),
        setIsLoading: (toggle) => dispatch(setIsLoading(toggle))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(ClientDetails);
