import store from '../store';

export const getTax = (amount) => {
    const storeData = store.getState()
    return (amount * storeData.account.paymentSettings?.tax) / 100
}

export const getDepositAmount = () => {
    const storeData = store.getState()
    const deposit = storeData.servicesTotals.totalAmount * (storeData.account.paymentSettings.deposit * 0.01)
    return deposit + getTax(deposit)
}

export const getPaymentAmountWithTax = () => {
    const storeData = store.getState()
    if (storeData.account.paymentSettings.deposit > 0) {
        return (storeData.servicesTotals.totalAmount + storeData.servicesTotals.totalTax) * (storeData.account.paymentSettings.deposit / 100)
    } else {
        return (storeData.servicesTotals.totalAmount + storeData.servicesTotals.totalTax)
    }
}

export const getPaymentAmount = () => {
    const storeData = store.getState()
    return storeData.account?.paymentSettings?.deposit > 0 ? storeData.servicesTotals.totalAmount * (storeData.account.paymentSettings?.deposit * 0.01) : storeData.servicesTotals.totalAmount
}