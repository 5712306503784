const dev = {
    WORKIZ_BOOKING_API: "https://dev.workiz.com/api/booking",
    BOOKING_APP_API_URL: "http://localhost:3001",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_51K0ilQFvYIFEBpv96LV0HTlHladDzf4SVhMvHzMAqeKuWjEkzz1FdB6YNWqUVuQYWcccRpkoBwAyurMnmi4nb2PI00SYtYaAIU",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c"

};

const staging = {
    WORKIZ_BOOKING_API: "https://userskill.tests.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions-staging.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_UXjks1mr98DmqsIKPhAiAatY00i2i2CD3M",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c"
};

const rc = {
    WORKIZ_BOOKING_API: "https://rc.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions-rc.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_UXjks1mr98DmqsIKPhAiAatY00i2i2CD3M",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c"
};

const prod = {
    WORKIZ_BOOKING_API: "https://app.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_live_znq8y1I8dlSbNdR144bX0i91",
    AMPLITUDE_API_KEY: "842f9ffb41146994896f90cbb7ed68f0"
};

const config = () => {
        switch(process.env.REACT_APP_STAGE) {
            case 'prod':
              return prod
              break;
            case 'rc':
                return rc;
                break;
            case 'staging':
               return staging
               break;
            case 'dev':
                return dev;
                break;
            default:
              return dev;
          }
    }
export default config();
