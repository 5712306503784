
export const updateServiceQuantity = (payload) => async dispatch => {
  dispatch({ type: 'UPDATE_SERVICE_QUANTITY', payload: payload });
};

export const updateServicesTotalAmount = (payload) => async dispatch => {
  dispatch({ type: 'UPDATE_SERVICES_TOTAL_AMOUNT', payload: payload });
};

export const updateServices = (payload) => async dispatch => {
  dispatch({ type: 'FETCH_SERVICES', payload: payload });
};
export const resetServiceTotal = (payload) => async dispatch => {
  dispatch({ type: 'RESET_SERVICE_TOTAL', payload: payload });
};







