const accountReducer = (state = [], action) => {
    switch (action.type) {
    case 'SET_ACCOUNT':
        return { ...state, account: action.payload };
    case 'FETCH_ACCOUNT':
        return action.payload;
    case 'SET_PAYMENT_SETTINGS':
        let account = state;
        account = { ...account, paymentSettings: action.payload };
        return account;
    default:
        return state;
    }
};
export default accountReducer;
