import React from 'react';
import './ConfirmationModal.scss'
import {connect} from "react-redux";
import {showConfirmationModal} from "../../actions/confirmationModalActions";

const ConfirmationModal = (props) => {

    const hideConfirmationModal = () => {
        const payload =  {
            show: false,
            content:{},
            approveFunction: null
        }
        props.showConfirmationModal(payload)
    }

    return (
        <div className="confirmation-modal-backdrop">
            <div className="confirmation-modal">
                <div className="title">{props.confirmationModal.content.title}</div>
                <div className="sub-title">{props.confirmationModal.content.subtitle}</div>
                <div className="buttons-wrapper">
                    <div onClick={() => {hideConfirmationModal()}} className="button cancel">{props.confirmationModal.content.cancelText}</div>
                    <div onClick={props.confirmationModal.approveFunction} className="button approve">{props.confirmationModal.content.approveText}</div>
                </div>
            </div>
        </div>
    );

}


const mapStateToProps = (state,ownProps) => {
    return {
        confirmationModal: state.confirmationModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showConfirmationModal: (payload) => dispatch(showConfirmationModal(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmationModal);

