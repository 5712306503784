import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import './OrderDetails.scss'
import * as dayjs from 'dayjs'
import shoppingCart from "@assets/images/cart.svg";
import {mobileMode} from "@helpers/mobileHelper";
import OrderDetailsRow from "./OrderDetailsRow/OrderDetailsRow";
import OrderDetailsTitle from "./OrderDetailsTitle/OrderDetailsTitle"
import {updateServiceQuantity,updateServicesTotalAmount} from "@actions/servicesActions";
import {showConfirmationModal} from "@actions/confirmationModalActions";


const OrderDetails = (props) => {
    let total = 0
    const [noServicesMode,setNoServicesMode] = useState(false)

    const renderTime = time => {
        if(time){
            const t1 = dayjs().hour(0).minute(0).add(time, 'hours')
            return t1.format(' h:mmA')
        }
    }

    const updateServiceQuantity = (service,action) => {

        if( props.activeStep > 1 &&
            props.numOfServices === 1 &&
            service.quantity === 1 &&
            action === 'remove'){
            const payload =  {
                show: true,
                content:{
                    title: "Remove last service item from your cart?",
                    subtitle: "All data will be reset and you will be redirected back to the main page",
                    approveText: "Yes, remove",
                    cancelText: "Cancel"
                },
                approveFunction: () => {
                    changeQuantity(service,action)
                    props.goBack()
                    props.showConfirmationModal({show:false})
                }
            }

            props.showConfirmationModal(payload)
        }else{
            changeQuantity(service,action)
        }
    }

    const changeQuantity = (service,action) => {
        props.changeServiceQuantity({serviceId:service.p_id,action:action})
        props.updateServicesTotalAmount(
            {
                price: service.booking_price,
                taxable: service.taxable,
                taxRate: props.account.paymentSettings?.tax,
                action
            })
    }

    const renderServices = () => {

        return props.services.map(service => {
            if(service.quantity > 0){
                total += (parseFloat(service.booking_price) * service.quantity)
                return <div key={service.p_id} className="service-wrapper">
                    <div className="service-details-row">
                        <div className="service-name">{service.item_name}</div>
                        <div className="service-quantity">
                            <span onClick={()=> updateServiceQuantity(service,'remove')} className="controls">-</span>
                            <span className="quantity">{service.quantity}</span>
                            <span onClick={()=> updateServiceQuantity(service,'add')} className="controls">+</span>
                        </div>
                        <div className="total-amount-sum">${service.booking_price * service.quantity}</div>
                    </div>
                </div>
            }else{
                return true
            }
        })
    }

    const getTotalAmount = () => {
        return !props.account.paymentSettings.tax ? props.totalAmount : props.totalAmount + props.totalTax
    }

    useEffect(() => {
        setNoServicesMode(props.services.length === 0 && !props.useJobTypes ? true : false)
    }, [props.services])

    return (
        <div className="order-details">
            {mobileMode() && <div onClick={props.toggleCart} className="close-wrapper">X CLOSE</div>}
            <div className="top">
                <div className="title">{noServicesMode ? 'Booking Details' : 'Your Order'}  </div>
                {
                    !noServicesMode &&
                    <div className="shopping-cart">
                        <div className="price">${props.totalAmount}</div>
                        <img alt="shopping-card" className="cart" src={shoppingCart}/>
                        {   props.numOfServices > 0 &&
                        <div className="quantity">{props.numOfServices}</div>
                        }
                    </div>
                }

            </div>
            <div className="wrapper">
                <div className="order-steps">
                    { (props.account.useJobTypes == 1 && props.hasJobTypes) && 
                        <div className="step">
                            {props.selectedJobType && <div className="progress-line"></div>}
                            <OrderDetailsTitle title={"Service type"} activeStep={props.activeStep} stepNum={1}/>
                            { props.selectedJobType && <div className="step-text">
                                <span>{ props.selectedJobType }</span>
                            </div> 
                            }
                        </div>
                    }
                    {   !noServicesMode &&
                        <div className="step">
                            {props.numOfServices > 0 && <div className="progress-line"></div>}
                            <OrderDetailsTitle title={"Service items"} activeStep={props.activeStep} stepNum={2}/>
                            <div className="services-wrapper">
                                {renderServices()}
                            </div>
                            {
                                props.totalAmount > 0 && props.account.paymentSettings?.tax > 0 &&
                                <div className="tax">
                                    <div className="text">Tax</div>
                                    <div className="amount">${props.totalTax}</div>
                                </div>
                            }
                            {
                                props.totalAmount > 0 &&
                                <div className={`total-payment ${!props.account.paymentSettings.tax > 0 ? 'no-tax' : ''}`}>
                                    <div className="text">Total Payment</div>
                                    <div className="amount">${getTotalAmount().toFixed(2)}</div>
                                </div>
                            }
                        </div>
                    }

                    <div className="step">
                        {props.activeStep > 2 && <div className="progress-line second"></div>}
                        <OrderDetailsTitle title={"Date & Time"} activeStep={props.activeStep} stepNum={3}/>
                        {props.activeStep > 1 &&
                            <div className="step-text date-time">
                                
                                <>{props.form.date ? dayjs(new Date(props.form.date)).format("MMMM DD, YYYY"): 'Please Choose Date'}</>
                                <>{renderTime(props.form.time)}</>
                            </div>
                        }
                    </div>

                    <div className="step">
                        {props.activeStep > 4 && <div className="progress-line third"></div>}
                        <OrderDetailsTitle title={"Address"} activeStep={props.activeStep} stepNum={4}/>
                        {   props.form.fullAddress &&
                            <div className="step-text address">
                            <span className="full-address-part">{props.form.fullAddress.address}{props.form.fullAddress.city && (props.form.fullAddress.state || props.form.fullAddress.zipcode) ? ', ' : ''}</span>
                            {props.form.fullAddress.city && <span className="full-address-part">{props.form.fullAddress.city}{props.form.fullAddress.city && (props.form.fullAddress.state || props.form.fullAddress.zipcode) ? ', ' : ''}</span>}
                            {props.form.fullAddress.state && <span className="full-address-part">{props.form.fullAddress.state}{props.form.fullAddress.state && props.form.fullAddress.zipcode ? ', ' : ''}</span>}
                            <span className="full-address-part">{props.form.fullAddress.zipcode}</span>
                            </div>
                        }
                    </div>
                    {
                        props.account.paymentSettings && props.account.paymentSettings.deposit >= 0 && !noServicesMode &&
                        <OrderDetailsRow stepNum={5} activeStep={props.activeStep} icon={"tools"} text={"Payment"}/>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        account: state.account,
        services: state.services,
        numOfServices: state.servicesTotals.numOfServices,
        totalAmount: state.servicesTotals.totalAmount,
        totalTax: state.servicesTotals.totalTax,
        form: state.form
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeServiceQuantity: (payload) => dispatch(updateServiceQuantity(payload)),
        updateServicesTotalAmount: (payload) => dispatch(updateServicesTotalAmount(payload)),
        showConfirmationModal: (payload) => dispatch(showConfirmationModal(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetails);
