const servicesTotalReducer = (state = [], action) => {
    switch (action.type) {

        case 'UPDATE_SERVICES_TOTAL_AMOUNT':
            let {totalAmount,numOfServices,totalTax} = state
            let newTotal = 0
            let newNumOfServices = 0
            let newTotalTax = 0

           if(action.payload.action === 'add'){
               newTotal = totalAmount + parseFloat(action.payload.price)
               newNumOfServices = numOfServices + 1
               if(action.payload.taxable == "1"){
                   newTotalTax = totalTax + (( action.payload.price * action.payload.taxRate) / 100)
               }else{
                   newTotalTax = totalTax
               }
           }else{
               newTotal = totalAmount - parseFloat(action.payload.price)
               newNumOfServices = numOfServices - 1
               if(action.payload.taxable == "1"){
                   newTotalTax = totalTax - (( action.payload.price * action.payload.taxRate) / 100)
               }else{
                   newTotalTax = totalTax
               }
           }
            return {...state,totalAmount:newTotal,numOfServices:newNumOfServices,totalTax: Math.round(newTotalTax * 100) / 100}
        case 'RESET_SERVICE_TOTAL':
            return {...state,totalAmount:0, numOfServices:0,totalTax: 0}

        default:
            return state;
    }
}

export default servicesTotalReducer
