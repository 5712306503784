import { combineReducers } from 'redux';
import servicesReducer from './servicesReducer';
import accountReducer from './accountReducer';
import formReducer from './formReducer';
import isLoadingReducer from './isLoadingReducer';
import servicesTotalReducer from './servicesTotalReducer';
import confirmationModalReducer from './confirmationModalReducer';
import jobTypeReducer from './jobTypeReducer';

export default combineReducers({
    services: servicesReducer,
    account: accountReducer,
    form: formReducer,
    isLoading: isLoadingReducer,
    servicesTotals: servicesTotalReducer,
    confirmationModal: confirmationModalReducer,
    selectedJobType: jobTypeReducer,
});
