import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import './App.scss';

//load js helpers
import moment from 'moment';
import * as urlHelper from '@helpers/urlHelper';
import {trackEventToAll} from "@helpers/eventsHelper";
import { amplitudeEvent } from '@api/amplitude';
import {mobileMode, isMobileDevice} from "@helpers/mobileHelper";
import {initSentry} from '@helpers/sentryHelper';
import {getResolution} from '@helpers/windowHelper';

//load redux actions
import {setAccount,getExtendedProperties} from './actions/accountActions'
import {updateServices, updateServicesTotalAmount, resetServiceTotal} from './actions/servicesActions'
import {updateSelectedJobType} from './actions/jobTypeAction'

//load containers
import Header from '@containers/Header/Header';
import OrderDetails from "@containers/OrderDetails/OrderDetails";
import ServicesList from '@containers/Services/ServicesList';
import ClientDetails from '@containers/ClientDetails/ClientDetails';
import ChooseDate from '@containers/ChooseDate/ChooseDate';
import ChooseTime from '@containers/ChooseTime/ChooseTime';
import Payment from "@containers/Payment/Payment";
import SuccessPage from '@containers/SuccessPage/SuccessPage';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import NotFound from '@containers/NotFound/NotFound';
import JobTypes from '@containers/JobTypes/JobTypes';

import {CircularProgress} from '@material-ui/core';
import config from './config';

import orderDetailsCart from '@assets/images/orderDetailsCart.svg';
import closeOrderDetailsCart from '@assets/images/arrow-down.svg';
import axios from "@api/axios";
import { initFullStory } from '@api/fullStory'
import {createAvailableSlots} from "@helpers/calendarHelper";
import ServiceDetailsModal from './containers/ServiceDetailsModal/ServiceDetailsModal';
import * as flagNames from './services/featureFlag/featureFlagConstants';
import { initFeatureFlag,isEnabled } from './services/featureFlag/featureFlag';


window.react = {}

const hours = {start: 0};
const defaultHours = {
    0: {...hours},
    1: {...hours},
    2: {...hours},
    3: {...hours},
    4: {...hours},
    5: {...hours},
    6: {...hours},
}
const PAGING_SIZE = 7;
const MAX_MOBILE_SIZE = 600;

class App extends Component {

    state = {
        activeStep: 0,
        noServicesMode: false,
        currentFlow: 'default',
        isLoading: false,
        isClientDetailsValid: false,
        isPaymentDetailsValid: false,
        showMobileCart: false,
        services: [1],
        enabledServices: [],
        completedSteps: 0,
        slotLength: 1,
        appointmentDate: moment().add(1, 'day'),
        takenSlots: null,
        userDetails: {},
        paging: -1,
        chooseTimeRefreshKey: 1,
        notFound: false,
        width: 0,
        mobileView: window.innerWidth < MAX_MOBILE_SIZE,
        showRequiredFields: false,
        outCalendarDays: null,
        serviceToShow:null,
        selectedJobType: null,
        techsTotalByJobTypes:{},
        jobTypes: [],
        selectedJobServices:[]
    };

    updateDimensions = () => {
        this.setState({ mobileView: window.innerWidth < MAX_MOBILE_SIZE});
    };

    nextStepText = () => {
        if(
            ((this.state.currentFlow === 'noPayment' || this.state.currentFlow === 'noServices' ) && this.state.activeStep === 3) ||
            (this.state.activeStep === 4)
        )
        {
            return ''
        }

        return 'NEXT STEP:'
    }

    goToNextStep = () => {
        
        if(this.state.activeStep == 4 && this.isNextStepDisabled()){
            this.setState({showRequiredFields: true});
            return;
        } 

        this.setState({showMobileCart: false})

        switch (this.state.activeStep) {
            case 1:
                this.setState({activeStep: this.getStepNumber(1)})
                break;
            case 2:
                trackEventToAll('bookingFinishedServicesStep',{
                    numItems:this.props.numOfServices,
                    totalPrice: this.props.totalAmount + this.props.totalTax
                });
                this.setState({activeStep: 3})
                break;
            case 3:
                trackEventToAll('bookingFinishedDateTimeStep');
                this.setState({activeStep: 4})
                break;
            case 4:
                this.clientDetailsRef.current.next()
                break;
            case 5:
                this.paymentRef.current.next()
                break;
            default:
                this.setState({activeStep: this.state.activeStep + 1})
        }
    }

    openServiceDetails = (service) => {
        this.setState({serviceToShow:service})
    }

    closeServiceDetails = () => {
        this.setServiceDetails(null)
    }

    isPaymentStepValid = (isValid) => {
        this.setState({isPaymentDetailsValid: isValid})
    }

    isClientDetailsStepValid = (isValid) => {
        this.setState({isClientDetailsValid: isValid})
    }

    isNextStepDisabled = () => {
        switch (this.state.activeStep) {
            case 1:
                return !this.props.selectedJobType;
            case 2:
                return this.props.numOfServices === 0;
            case 3:
                return !(this.props.form.date && this.props.form.time !== null)
            case 4:
                return !this.state.isClientDetailsValid
            case 5:
                return !(this.state.isPaymentDetailsValid || this.props.account.paymentSettings?.gateway === 'workiz-stripe')
            default:
                return true
        }
    }

    renderNextButtonText = () => {
        switch (this.state.activeStep) {
            case 1:
                return this.state.selectedJobServices.length > 0 ? 'Choose Service':'Choose Date & Time'
            case 2:
                return 'Choose Date & Time'
            case 3:
                return 'Provide address details'
            case 4:
                return  this.state.currentFlow == 'noPayment' || this.state.noServicesMode || this.state.currentFlow === 'noServices' || !this.props.totalAmount ? 'Complete booking' : 'Provide payment details'
            case 5:
                return 'Complete booking'
            default:
                return ''
        }
    }


    shouldRenderOrderDetails = () => {
        if (!mobileMode()) {
            return true
        } else if (mobileMode() && this.state.showMobileCart) {
            return true
        } else {
            return false
        }
    }

    toggleCart = () => {
        this.setState({showMobileCart: !this.state.showMobileCart})
    }

    redirectToThankYouPage = () => {
        window.top.location = this.props.account.thankYouUrl;
    }

    onClientDetailsDone = (hasPayment) => {
        if(hasPayment){
            this.setState({activeStep: 5})
        }else{
            if(this.props.account.thankYouUrl.length > 0){
                this.redirectToThankYouPage();
            }else{
                this.setState({activeStep: 6})
            }
        }
    }

    onPaymentDone = () => {
        if(this.props.account.thankYouUrl){
            this.redirectToThankYouPage();
        }else{
            this.setState({activeStep: 6})
        }
    }

    onSelectedJobType = async (selectedJobType) => {
        let {accountSettings} = this.state
        console.log(this.state.techsTotalByJobTypes);
        console.log(selectedJobType);
        const total_techs = this.state.techsTotalByJobTypes[selectedJobType] ? this.state.techsTotalByJobTypes[selectedJobType].length :await this.getActiveUsers()
        this.setState({
            accountSettings: {...accountSettings, total_techs: total_techs},
        })
        this.setState({selectedJobType: selectedJobType}, this.loadSlots);
        this.setServiceByJobType(selectedJobType);
        this.props.dispatchUpdateSelectedJobType(selectedJobType)
    }

    resetServicesAmount = async () => {
        this.setState({activeStep: 1})
        const services = await this.props.getExtendedProperties(this.props.account.ac)
        this.props.dispatchUpdateServices(services)
        this.props.dispatchResetServiceTotal()
    }

    getStepContent = () => {
        return (
            <div className="main">
                <div
                    className={`content ${this.state.activeStep === 6 || this.state.activeStep === 0 ? 'no-order-details-wrapper' : '' }`}>
                    <div className={`steps step${this.state.activeStep}`}>
                        <ServiceDetailsModal
                            isOpen={this.state.serviceToShow !== null}
                            closeAction={() => { this.setState({ serviceToShow: null }) }}
                            serviceInfo={this.state.serviceToShow}
                            accountTaxRate={this.props.account.paymentSettings?.tax}
                        />
                        { 
                        (this.state.activeStep === 1 && this.state.accountSettings.use_job_types && this.state.jobTypes?.length > 0) && <div>
                            <JobTypes
                            jobTypes = {this.state.jobTypes}
                            onSelectedJobType = {(jobType)=>this.onSelectedJobType(jobType)}
                            />
                            { this.state.isLoading && <div className="main-loader">
                                <CircularProgress/>
                            </div> 
                            }
                        </div>
                        }
                        {
                            this.state.activeStep === 2 &&
                            <ServicesList
                                goBack={() => this.resetServicesAmount()}
                                openServiceDetails = {(service)=>this.openServiceDetails(service)}
                                hasJobTypes={this.state.jobTypes.length > 0}
                            />
                        }
                        {
                            (this.state.activeStep === 3 && this.state.outCalendarDays != null)&&
                            <ChooseDate
                                goBack={() => this.setState({activeStep: this.getStepNumber(3,true)})}
                                loadSlots={(month, year, isNext) => {
                                    this.setState({appointmentSlot:null})
                                    this.loadSlots(month, year, isNext);
                                }}
                                paging={this.state.paging}
                                pagingSize={PAGING_SIZE}
                                working_times={this.state.accountSettings.working_times_obj}
                                enabledServices={this.state.enabledServices}
                                jobTypes={this.state.jobTypes}
                                outCalendarDays={this.state.outCalendarDays }
                                val={this.state.appointmentDate || new Date()}
                                use_job_types={this.state.accountSettings.use_job_types}
                                onDone={selectedDate => {
                                    if(selectedDate == null) {
                                        selectedDate = moment().add(1, 'day')
                                    }
                                    this.setState({
                                        chooseTimeRefreshKey: this.state.chooseTimeRefreshKey +1,
                                        confirmationTextVisible: true,
                                        appointmentDate: selectedDate,
                                        appointmentSlot: "-1"
                                    })
                                }}
                            />
                        }

                        {
                            (this.state.activeStep === 3 && this.state.takenSlots != null) &&

                            <ChooseTime
                                key={this.state.chooseTimeRefreshKey}
                                takenSlots={this.state.takenSlots}
                                goBack={() => this.setState({activeStep: 1})}
                                accountSettings={this.state.accountSettings || {}}
                                workingTimes = {this.state.accountSettings.working_times_obj}
                                choosenDay={this.props.form.date ||this.state.appointmentDate}
                                scheduleOffset={this.state.accountSettings.schedule_offset || 0}
                                slotLength={this.state.accountSettings.slot_length || 1.00}
                                availableBased ={this.state.accountSettings.available_based || 1}
                                availableJobsPerSlot ={this.state.accountSettings.availble_techs}
                                totalTechs = {this.state.accountSettings.total_techs}
                                onDone={(appointmentSlot) => this.setState({
                                    activeStep: 3,
                                    appointmentSlot,
                                    disabledNext: false
                                })}
                            />

                        }

                        {
                            this.state.activeStep === 4 && <ClientDetails
                                goBack={() => {
                                    this.setState({activeStep: 3, showRequiredFields: false })
                                }}
                                ref={this.clientDetailsRef}
                                isValid={(valid) => this.isClientDetailsStepValid(valid)}
                                onDone={(hasPayment) => this.onClientDetailsDone(hasPayment)}
                                showRequired={this.state.showRequiredFields}
                                jobTypes = {this.state.jobTypes}
                                selectedJobType={this.state.selectedJobType}
                            />
                        }

                        {
                            this.props.totalAmount > 0 && this.props.account.paymentSettings?.deposit >= 0 
                            && this.state.activeStep === 5 && <Payment
                                ref={this.paymentRef}
                                isValid={(valid) => this.isPaymentStepValid(valid)}
                                goBack={() => this.setState({activeStep: 3})}
                                onDone={() => this.onPaymentDone()}
                                jobTypes = {this.state.jobTypes}
                                selectedJobType={this.state.selectedJobType}
                            />
                        }

                        {
                            this.state.activeStep === 6 && <SuccessPage
                                accountSettings = {this.state.accountSettings}
                                jobTypes = {this.state.jobTypes}
                                selectedJobType={this.state.selectedJobType}
                            />
                        }
                    </div>
                    {(this.state.activeStep > 0 && this.state.activeStep < 6) &&
                    <div
                        className={`order-details-wrapper  ${this.state.activeStep === 3 ? 'without-margin-left' : ''} ${this.state.showMobileCart && this.state.mobileView ? 'full-order-details' : ''} ${ this.state.activeStep === 3 ? 'step3wrapper' : ''}`}>
                        {
                            this.shouldRenderOrderDetails() && <OrderDetails
                                activeStep={this.state.activeStep}
                                toggleCart={this.toggleCart}
                                goBack={() => this.setState({ activeStep: (this.state.accountSettings.use_job_types && this.state.jobTypes?.length > 0) ? 1 : 2 })}
                                hasJobTypes={this.state.jobTypes.length > 0 }
                                selectedJobType={this.state.jobTypes.find(jobType => jobType?.id == this.state.selectedJobType)?.type_name }
                                useJobTypes={this.state.accountSettings.use_job_types}
                            />
                        }
                        <div className="bottom-wrapper">
                            {
                                !this.state.noServicesMode &&
                                <div onClick={() => this.toggleCart()} className="cart-wrapper">
                                    {!this.state.showMobileCart &&
                                        <div>
                                            <img alt="shopping cart" src={orderDetailsCart}/>
                                            {this.props.numOfServices > 0 && <div className="services-quantity">{this.props.numOfServices}</div>}
                                        </div>
                                    }
                                    {this.state.showMobileCart && <img alt="close shopping cart" src={closeOrderDetailsCart}/>}
                                </div>
                            }

                            {!this.state.noServicesMode && <div className = {`separator ${ this.state.activeStep === 3 ? 'step3seperator' : ''}`}></div>}
                            <div className={`next-step-wrapper ${this.state.noServicesMode ? 'no-cart' : ''} ${ this.state.activeStep === 3 ? 'step3' : ''}`}>
                                {<div className="next-step">{this.nextStepText()}</div>}
                                <div onClick={() => !this.isNextStepDisabled() && this.goToNextStep() }
                                     className={`next-step-button ${isMobileDevice() ? "" : "with-hover"} ${this.isNextStepDisabled() ? (this.state.activeStep === 3 ? 'semi-disabled' : 'disabled') : '' }`}>
                                <span>
                                    {this.renderNextButtonText()}
                                </span>
                                    {
                                        this.props.isLoading &&
                                        <span className="spinner-loader"></span>
                                    }

                                    <span>
                                    <i className="fas fa-chevron-right"></i>
                                </span>
                                </div>
                                { this.state.activeStep === 3 && <div className='agree-sms-warning'>
                                        By booking a job, you agree to receive<br/>
                                        SMSs regarding the booking requested
                                </div> }
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>

        )
    }

    getWorkingHours = (working_times, slot_length) => {
        slot_length = slot_length ? slot_length : 1.00;
        let working_times_obj = JSON.parse(working_times || "{}");
        working_times_obj = working_times_obj || {};
        if (!Object.keys(working_times_obj).length) {
            working_times_obj = {...defaultHours};
            return working_times_obj;
        }
        for (let i in working_times_obj) {
            working_times_obj[i] = working_times_obj[i] || {};
            working_times_obj[i].lastSlot = Math.floor(((working_times_obj[i].end || 0) - (working_times_obj[i].start || 0)) / slot_length);
        }
        return working_times_obj;
    }
    
    loadSlots = async (month = null, year = null, isNext = false) => {
        let startDate
        let endDate
        if(month !== null && year !== null){
           startDate = moment().set({'year': year, 'month': month }).startOf('month').format("YYYY-MM-DD");
           endDate = moment().set({'year': year, 'month': month+1}).startOf('month').format("YYYY-MM-DD");
        } else {
            startDate = moment().startOf('month').format("YYYY-MM-DD");
            endDate = moment().endOf('month').format("YYYY-MM-DD");
        }
        this.setState({isLoading: true})

        //TODO cheack then conmes new one
        let {ac, uc, accountSettings, paging, jobTypes, techsTotalByJobTypes, selectedJobType} = this.state
        const use_job_type = accountSettings.use_job_types == 1 && jobTypes.length > 0
        let techsArray = []
        if(use_job_type && selectedJobType){
            techsArray = techsTotalByJobTypes[selectedJobType] ?? []
        }
        paging++;
        if (paging === 0 && accountSettings.schedule_offset === 7 * 24) {
            paging = 1;
        }
        this.setState({
            isLoading: true,
            takenSlots:null,
        })
        axios.post(`${config.BOOKING_APP_API_URL}/getSlots`, {
            ac, uc,
            action: 'getSlots',
            slotLength: accountSettings.slot_length,
            startAt: accountSettings.working_start_time,
            available_based: accountSettings.available_based,
            startDate,
            endDate,
            use_job_type,
            techsArray
        }).then(res => {
            this.setState({isLoading: false})
            
            if (res.data.flag && Array.isArray(res.data.data)) {
                const takenSlots = {};
                const data = res.data.data;
                let outCalendarDays = [];
                data.forEach(item => {
                    takenSlots[item.job_day] = takenSlots[item.job_day] || {};
                    takenSlots[item.job_day][item.slot_key] = {tech_in_job:item.counter, tech_in_day_off: item.day_off};
                });
                if(accountSettings.available_based !== 0 ){
                    if(!this.state.accountSettings.working_times_obj){
                        this.setState({
                            accountSettings: {...accountSettings}
                        }
                        )
                    }

                    outCalendarDays = this.getBusyDays(this.state.accountSettings, takenSlots);                                    
                } 

                this.setState({
                    takenSlots: takenSlots,
                    paging,
                    outCalendarDays,
                    accountSettings: {...accountSettings}
                });
            }
        });
    }

    constructor(props) {
        super(props);
        initSentry()
        this.clientDetailsRef = createRef();
        this.paymentRef = createRef();
    }

    getUserFields = (userFields, region) => {
        if(Object.keys(userFields).length > 0){
            if(userFields.state && (region == 'NON' || region == null)){
                delete userFields.state;
            }
            return userFields
        }else{
            return {
                "user_name": {"label":"Your Name","type":"text","required":true,"position":0},
                "email_address":{"label":"Email Address","type":"email","required":true,"position":1}
            }
        }

    }

    isPaymentsDisabled = () => {
        return this.props.account.paymentSettings?.deposit === -1 || this.state.enabledServices?.length === 0;
    }

    getCurrentFlow = (services) => {
        if(services?.length === 0){
            return 'noServices'
        }else if(this.isPaymentsDisabled()){
            return 'noPayment'
        }else{
            return 'default'
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    }

    async componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.setState({isLoading: true})

        const query = window.location.search;
        const params = urlHelper.decodeQuery(query);
        const ac = params.ac;
        const uc = params.uc;
        const urlAdGroup = params.ad_group;

        await axios.post(`${config.BOOKING_APP_API_URL}/getSettings`, {ac:ac, uc:uc, action: 'getSettings'}).then( async res => {

            const settings = res.data.data
            this.props.dispatchSetAccount({
                accountId: settings.account_id,
                ac: ac,
                accountLogo: settings.account_logo,
                uselogo: settings.uselogo,
                adGroup: settings.ad_group,
                adGroupName: urlAdGroup,
                color: settings.color,
                title: settings.title,
                description: settings.description,
                schedule_offset: settings.schedule_offset,
                thankYouUrl: settings.thank_you_url,
                slotLength: settings.slot_length,
                telephone: settings.sms_notification,
                email: settings.email_notification,
                userFields: this.getUserFields(settings.user_fields ? JSON.parse(settings.user_fields) : [], settings.region),
                region: settings.region,
                useJobTypes: settings.use_job_types,
                jobTypes: settings.selected_job_types,
            })

            initFullStory(settings.account_id)
            initFeatureFlag(this.props.account.accountId);

            const services = await this.props.getExtendedProperties(ac)
            const enabledServices = services && services.length >0 && services.filter((service) => {
                return service.status === "1"
            })
            this.setState({enabledServices:enabledServices})
            if (res.data.flag && res.data.data) {
                let accountSettings = settings || {};
                if (uc && accountSettings.available_based === 1) {
                    accountSettings.availble_techs = 1;
                }
                if(settings.use_job_types){
                    let techsTotal = await this.getTotalTechsByJobType(ac)
                    this.setState({techsTotalByJobTypes:techsTotal})            
                }else if(this.state.jobTypes.length == 0){
                    accountSettings.total_techs =await this.getActiveUsers()
                }
                this.setState({
                    themeColor: accountSettings.color || "#3b4b52",
                    ac, uc, accountSettings, slotLength: accountSettings.slot_length
                },);
                this.setState({
                    isLoading: false,
                    noServicesMode: enabledServices?.length > 0 ? false : true,
                    currentFlow: this.getCurrentFlow(services)
                })
                const jobTypes = await this.getJobTypes()
                this.setState({jobTypes:jobTypes})
                this.setState({
                    accountSettings:{...accountSettings,
                    working_times_obj:this.getWorkingHours(settings.working_times, settings.slot_length)
                    }
                }, this.loadSlots)
                trackEventToAll('bookingPageLoaded',{
                    urlReferral:document.referrer,
                    isEmbedded:window.location != window.parent.location,
                    paymentsEnabled: !this.isPaymentsDisabled(),
                    itemsEnabled: enabledServices?.length > 0,
                    screenResolution: getResolution()
                });
            }
            const firstStep = this.getFirstStepNumber(enabledServices)
            this.setState({activeStep: firstStep})
        }).catch((e)=>{            
            this.setState({isLoading: false, notFound: true})
        });


    }
    //calculate daysOutCalendar
    getBusyDays = (accountSettings, takenSlots) => {
    if(!accountSettings.working_times_obj) return;
       let  busyDaysSlots = busyDaysSlots || {};  
       let newOutDays = this.state.outCalendarDays ? [...this.state.outCalendarDays]:[]
        // Calculate each day availability hours
        for (let date in takenSlots) { 
            let parsedDate = moment(date, 'DD-MM-YY').format('LLLL');
            busyDaysSlots = createAvailableSlots(parsedDate, takenSlots, accountSettings.working_times_obj, accountSettings.slot_length, accountSettings.schedule_offset, accountSettings.available_based, accountSettings.availble_techs, accountSettings.total_techs);                                
            if(busyDaysSlots.length == 0 && !newOutDays.includes(date)){
                newOutDays.push(date);                
            }
        }
        const firstPossibleAvailableDate = moment(new Date()).add(accountSettings.schedule_offset, 'hours')
        let dateForCheck = new Date();
        if(moment(dateForCheck).format('DD-MM-YY') != firstPossibleAvailableDate.format('DD-MM-YY')){
            while(firstPossibleAvailableDate.isAfter(dateForCheck)){
                newOutDays.push(moment(dateForCheck).format('DD-MM-YY'));
                dateForCheck.setDate(dateForCheck.getDate()+1)
            }
        }
        return newOutDays;
    }

    getFirstStepNumber = (enabledServices) => {
        if(this.state.accountSettings.use_job_types && this.state.jobTypes.length > 0) {
            return 1;
        } else {
            const numStep = enabledServices?.length > 0 ? 2 : 3;
            return numStep;
        }
    }

    getActiveUsers = async () => {
        const total_techs = await axios.post(`${config.WORKIZ_BOOKING_API}/getActiveUsers/`,{accountId: this.props.account.accountId})
        return  total_techs.data.total_techs
    }

    getStepNumber = (currentStep, back = false) => {
        if(this.props.services.length == 0){
            if(currentStep === 1){
                return 3;
            }if(back){
                return 1;
            }
        }
        const step = back ? -1:1
        return currentStep + step
    }

    getJobTypes = async () => {
        const typesArray = [];
        const featureFlagOn = await isEnabled(flagNames.JOB_TYPES_BOOKING, false);
        if(featureFlagOn){
            const response = await axios.post(`${config.WORKIZ_BOOKING_API}/getJobTypes/`,{accountId: this.props.account.accountId})
            const allJobTypes = response.data.jobTypes
            const bookingJobTypes = this.props.account.jobTypes ? JSON.parse(this.props.account.jobTypes) : [];
            if(allJobTypes.length > 0){
                bookingJobTypes.forEach(type => {
                    let typeData = allJobTypes.find(t => t.id == type)
                    typesArray.push(typeData)
                })
            }
            if (this.state.accountSettings.use_job_types) {
                amplitudeEvent("bookingPageJobTypesLoaded", {
                    numOfServices: typesArray.length
                })
            }
        }
        return typesArray;
    }


    setServiceByJobType = async (type) => {
        const services = await this.props.getExtendedProperties(this.props.account.ac)
        const selectedJobServices = services.filter(service => service.job_types == type || service.job_types == "" ||  service.job_types === undefined ||  service.job_types === null)
        this.props.dispatchUpdateServices(selectedJobServices)
        this.setState({selectedJobServices:selectedJobServices})
    }

    getTotalTechsByJobType = async  (ac) => {
        const total_techs_by_type = {}
        axios.post(`${config.BOOKING_APP_API_URL}/getTechsByJobType`, {
            ac: ac,
            action: 'getTechsByJobType',
        }).then(res => {
            this.setState({isLoading: false})
            if (res.data.flag) {
                const job_types = res.data.data;
                job_types.forEach((element) => {
                    total_techs_by_type[element.job_type_id] = element.techs_id.split(','); 
                })
            }
        })
        return total_techs_by_type;

    }



    render() {
        return (
            this.state.notFound ? 
            <NotFound/> 
            :
           <>
               {this.props.confirmationModal.show && <ConfirmationModal/>}
               <div className={`booking ${this.shouldRenderOrderDetails() && mobileMode() ? 'not-scrollable' : ''}
                    ${window.location != window.parent.location && !isMobileDevice() ? 'iframe' : ''}`}>
                   <Header/>
                   {mobileMode() && this.shouldRenderOrderDetails() && <div className="mobile-gap"></div>}
                   {this.getStepContent()}
               </div>
           </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        account: state.account,
        services: state.services,
        form: state.form,
        totalAmount: state.servicesTotals.totalAmount,
        totalTax: state.servicesTotals.totalTax,
        numOfServices: state.servicesTotals.numOfServices,
        isLoading: state.isLoading,
        confirmationModal: state.confirmationModal,
        useJobTypes: state.useJobTypes,
        selectedJobType: state.selectedJobType
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getExtendedProperties: (payload) => dispatch(getExtendedProperties(payload)),
        dispatchSetAccount: (payload) => dispatch(setAccount(payload)),
        dispatchUpdateServices: (payload) => dispatch(updateServices(payload)),
        dispatchUpdateServicesTotalAmount: (payload) => dispatch(updateServicesTotalAmount(payload)),     
        dispatchUpdateSelectedJobType: (payload) => dispatch(updateSelectedJobType(payload)),   
        dispatchResetServiceTotal: (payload) => dispatch(resetServiceTotal(payload)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);