import React from 'react';
import './ServiceDetailsModal.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import closeImg from '@assets/images/close.svg';
import { useDispatch} from "react-redux";
import {updateServiceQuantity, updateServicesTotalAmount} from '@actions/servicesActions';



const ServiceDetailsModal = ({isOpen, closeAction, title, serviceInfo, accountTaxRate}) => {
    const dispatch = useDispatch();

    const DetailsContent = () => {
        if(!serviceInfo) return null;
        return <div className="detailsModal">
            {serviceInfo.img_url && <div className="image">
                <div className="price intoImg">${serviceInfo.booking_price}</div>
                <img className='img' alt='service' src={serviceInfo.img_url} />
            </div>}
            <div className="wrapper-details">
                <div className="title">{serviceInfo.item_name}</div>
                <div className={`price ${serviceInfo.img_url ? 'priceModal' : ''}`}>${serviceInfo.booking_price}</div>
                {renderAddToCart()}
                <div className={`description ${serviceInfo.img_url ? '' : 'noImg'}`}>{serviceInfo.long_desc}</div>
            </div>
        </div>;
    }

    const quantityChange = (action) => {
        dispatch(updateServiceQuantity({serviceId: serviceInfo.p_id, action}));
        dispatch(updateServicesTotalAmount({
                price: serviceInfo.booking_price,
                taxable: serviceInfo.taxable,
                taxRate: accountTaxRate,
                action
            }));
    }

    const renderAddToCart = () => {
        if (serviceInfo.quantity === 0) {
            return <div className="addToCartButton" onClick={() => {  quantityChange('add') }}>
                <span>Add to Cart</span>
            </div>
        }
        else {
            return <div className="quantity-wrapper">
                <div onClick={() =>  quantityChange('remove')}
                    className="quantity-circle remove">-
                </div>
                <div onClick={() => {
                     quantityChange('add')
                }} className="quantity-circle add">+
                </div>
                <div className="quantity">{serviceInfo.quantity}</div>
            </div>
        }
    }




    return (
        <Dialog
            open={isOpen}
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            id="dialog"
            PaperProps={{
                style: {
                  borderRadius: '8px',
                  margin: '0px',
                  maxWidth: 'calc(100% - 30px)',
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">
            <span className='closeIcon' onClick={closeAction}>
                <img src={closeImg}/>
            </span>
                {title}
            </DialogTitle>
            <DialogContent>
                    {DetailsContent()}
            </DialogContent>
        </Dialog>
    )
}

export default ServiceDetailsModal;
