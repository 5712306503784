import launchDarklyTokens from "./launchDarklyTokens";
const LDClient = require('launchdarkly-js-client-sdk');

let client;

/* LDClient must be a singleton
 * It's important to make LDClient a singleton. 
 * The client instance maintains internal state that allows us to serve feature flags without making any remote requests.
 * Do not instantiate a new client with every request.
*/

export const initFeatureFlag = async (accountId) => {
    const user = { key: accountId }

    const token = getTokenByEnv();
    if (!token) {
        throw new Error('Could not find launchDarkly token for env');
    }
    client = LDClient.initialize(token, user);
    client.on('ready', onReady);
}

export const isEnabled = async (flagName, defaultValue = false) => {
    try {
        await client.waitForInitialization();
        const res = await client.variation(flagName, false);
        return res;
    }
    catch (e) {
        return defaultValue;
    }
}

export const getFlags = async () => {
    await client.waitForInitialization();
    return await client.allFlags().toJSON();
}

function getTokenByEnv() {
    const env = window.location.hostname.split(".")[0];
    return launchDarklyTokens[env] ?? launchDarklyTokens["staging"];
}

function onReady() {
    console.log("connected successfully");
}

export const cleanup = async () => {
    await client.close();
}