import React  from 'react';
import './OrderDetailsTitle.scss'

const OrderDetailsTitle = (props) => {
    return (
        <div className={`order-details-title ${props.activeStep >= props.stepNum ? 'active' : ''}`}>
            <span>{props.title}</span>
            <div className="circle"></div>
        </div>
    )
}

export default OrderDetailsTitle;

