import React, { Component } from 'react';
import {connect, useDispatch} from "react-redux";
import './ChooseDate.scss'
import { getMonth,getYear } from 'date-fns';
import {updateForm} from "@actions/formActions";
import StepTitle from "@components/StepTitle/StepTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useMemo } from 'react';

const ChooseDate = ({working_times, enabledServices, outCalendarDays, onDone, loadSlots, form, goBack, preSetDate, use_job_types, jobTypes }) => {
    const [initialDate, setInitialDate] = useState(new Date()|| preSetDate);///TODO: chec this case
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [workingTimes, setWorkingTimes] = useState(working_times);
    const [activeMonth, setActiveMonth] = useState(initialDate.getMonth());
    const [activeYear, setActiveYear] = useState(getYear(initialDate));


    const dispatch = useDispatch();

    useEffect(()=>{    
        loadSlots(activeMonth, activeYear);
    }, [activeMonth])

    let unavailableDates = useMemo(()=>{
        return createUnavailableDates(outCalendarDays, workingTimes, activeMonth, activeYear);  
    }, [activeMonth, outCalendarDays.length])
  
    useEffect(() => {
        setFirstAvailableDateOfMonth(activeMonth, activeYear);
    }, [unavailableDates])

    useEffect(() => {
        dispatch(updateForm({   
                type:'date',data: selectedDate}));

       dispatch(updateForm({   
                type:'time',data: null}));
        onDone(selectedDate);
    }, [selectedDate])

    function createUnavailableDates(outCalendarDays, workingTimes, currentMonth, currentYear){
        let unavailableDates = []; 
        const today = new Date();    
        const countDaysInMonth = getDaysInMonthCount(currentMonth, currentYear);
        //create dates from work grapich                
        for(let i=0; i < countDaysInMonth; i++) {
            if (!isWorkingDay(currentYear, currentMonth, i+1, workingTimes)){
                unavailableDates.push(new Date(currentYear, currentMonth, i+1))
            } 
        }
        //create dates for days already passed
        if(currentMonth === today.getMonth() && currentYear === today.getFullYear()){
            let dateInMonth = 1
            while(dateInMonth<today.getDate()){
                let dayToRemove = new Date(currentYear, currentMonth, dateInMonth);                
                unavailableDates.push(dayToRemove);
                dateInMonth++;
            }
        }
        //remove dates from alredy taken
        const takenDates = outCalendarDays.map(date => {            
            let parsedDate = date.split('-');
            return new Date( `20${parsedDate[2]}`, parsedDate[1]-1, parsedDate[0]);
        })
        unavailableDates = [...takenDates, ...unavailableDates];        
        return unavailableDates;
    }

    const onChangeDate = (date) => {     
        setSelectedDate(date);
    }

    function isWorkingDay(year, month, day, workingTimes){                   
        let dayNumber = new Date(year, month, day).getDay() - 1;  
        if(dayNumber == -1) dayNumber = 6;     //TODO wTF
        return workingTimes[dayNumber];
    }

    function getDaysInMonthCount (month, year){
        return new Date(year, month+1, 0).getDate();
    }

    const getDaysInMonth = (month, year)  => {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days;
    }



    const calcDatesPerMonth = (changeMonthFunc) => {
        changeMonthFunc();
    }

    const findAvailableDates = (monthDays, unavailableDates, month) => {        
        let parsedMonthDay = monthDays.map((day)=>day.getDate());
        let parsedUnavailableDates = unavailableDates.filter((day)=>{
            if(day.getMonth()== month){
             return day
            }
        }).map((day)=>day.getDate());        
        let availableDates = [];
        parsedMonthDay.forEach(day=>{            
            if(parsedUnavailableDates.indexOf(day)===-1){
                availableDates.push(day)
            }
        })
        return availableDates
    }

    const setFirstAvailableDateOfMonth = function(activeMonth, activeYear){
        let firstAvailableDay
        let monthDays = getDaysInMonth(activeMonth, activeYear);
        let availableDates = findAvailableDates(monthDays, unavailableDates, activeMonth);    
        availableDates.length > 0 ? firstAvailableDay = new Date (activeYear, activeMonth, availableDates[0]) : firstAvailableDay = null;
        onChangeDate(firstAvailableDay)
    }

    const getMonthFullName = (date) =>{
        return date.toLocaleString('default', { month: 'long' })
    }

    const isGoBackButtonDisabled = () =>{ 
        if(use_job_types && jobTypes?.length) return false;
        else{
            return jobTypes?.length === 0 || !enabledServices || enabledServices.length === 0;
        }
    }

    const customHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {                     
        if(date.getMonth() !== activeMonth){
            setActiveMonth(date.getMonth())
            setActiveYear(getYear(date));
        }
      return  <div className="datepicker-header">
            <div className="current-period">
                <span className="month">{getMonthFullName(date)}</span>
                <span className="year">{getYear(date)}</span>
            </div>
            <div className="change-month-wrapper">
                <div className="change-month previous"
                    onClick={() => {
                        if(!prevMonthButtonDisabled)calcDatesPerMonth(decreaseMonth, date)}}
                    disabled={prevMonthButtonDisabled}></div>
                <div className="change-month next"
                    onClick={() => {calcDatesPerMonth(increaseMonth, date, true)}}
                    disabled={nextMonthButtonDisabled}></div>
            </div>
        </div>
    }
        return (
            <div className="choose-date">
                <StepTitle disableBack={isGoBackButtonDisabled()} back={() => goBack()} title="Schedule a service" subtitle="/ Select a date & time"/>
                <div className="datepicker-wrapper">
                    <DatePicker
                        useWeekdaysShort={true}
                        renderCustomHeader={customHeader}
                        selected={selectedDate}
                        onChange={onChangeDate}
                        name="date"
                        excludeDates={unavailableDates}
                        inline
                        minDate={new Date()}
                        calendarClassName="booking-date-picker"
                        calendarStartDay={1}
                    />
                </div>
            </div>
        );
    
}



export  default ChooseDate;
