import React, { useState } from 'react';
import { connect } from 'react-redux'
import Select from '@components/Select/Select';
import StepTitle from "@components/StepTitle/StepTitle";
import './JobTypes.scss';

const JobTypes = (props) => {

    const onClick = (type) => {
        props.onSelectedJobType(type)
    }

    return (
        <div className="job-types">
            <StepTitle title="Service type" disableBack={true} subtitle="/ Select a service that you need"/>
            <div className="job-types-options">
                {props.jobTypes.map((jobType) =>
                    <Select
                        item={jobType}
                        onClick={(type) => onClick(type)}
                        isSelected={jobType.id === props.selectedJobType}
                    />
                )}

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    selectedJobType: state.selectedJobType,
})

export default connect(mapStateToProps)(JobTypes)

